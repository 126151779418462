// extracted by mini-css-extract-plugin
export var BlackTile = "Landing-module--BlackTile--M8JKK";
export var ButtonLink = "Landing-module--ButtonLink--F0vkz";
export var ButtonWrapper = "Landing-module--ButtonWrapper --otu1E";
export var Caption = "Landing-module--Caption--MuI6I";
export var CarouselWrapper = "Landing-module--CarouselWrapper--7cCRy";
export var CarrouselWrapper = "Landing-module--CarrouselWrapper--BeI5r";
export var CartelDiv = "Landing-module--CartelDiv--8zVIp";
export var CartelGrid = "Landing-module--CartelGrid--addc3";
export var CartelImg = "Landing-module--CartelImg--MqtFh";
export var FormFieldWrapper = "Landing-module--FormFieldWrapper--GLDUb";
export var ImageItem = "Landing-module--ImageItem--fLcTU";
export var InputWrapper = "Landing-module--InputWrapper--epHTG";
export var LandingSlider = "Landing-module--LandingSlider--kvVJL";
export var ListWrapper = "Landing-module--ListWrapper--GVd-2";
export var ModalPhoneGrid = "Landing-module--ModalPhoneGrid--P2vvq";
export var ModalPhoneWrapper = "Landing-module--ModalPhoneWrapper--h2Kv4";
export var NewDescription = "Landing-module--NewDescription--XBRKk";
export var NewsLetterDescription = "Landing-module--NewsLetterDescription--tUTpL";
export var PhotosWrapper = "Landing-module--PhotosWrapper--RXFVI";
export var PreCartelImg = "Landing-module--PreCartelImg--swJON";
export var Separator = "Landing-module--Separator--1xNeb";
export var Wrapper = "Landing-module--Wrapper--U-twj";
export var isVisible = "Landing-module--is-visible--eH9w6";
export var isnotVisible = "Landing-module--isnot-visible--ci0L7";
export var out = "Landing-module--out--VokyI";
export var zoom = "Landing-module--zoom--yVfuq";
export var zoomOut = "Landing-module--zoom-out--OZ1PX";